import { format as formatDate } from "date-fns";
import sleep from "sleep-promise";
import { get } from "svelte/store";

import backendApi from "~/libs/backendApi";
import { DeliveryRecordTypes, DriverType } from "~/libs/constants";
import deliveryListUtils from "~/libs/deliveryListUtils";
import geolocator from "~/libs/geolocator";
import logger from "~/libs/logger";
import { calcDistance } from "~/libs/mapUtils";
import { currentPositionStore } from "~/libs/stores";
import { getCurrentDateTimeOnJst } from "~/libs/utils";

const CURRENT_IF_VERSION = 1;

/** @type {string} 現在時刻 */
let now = "";

/** @type {GeolocationPosition} 現在位置 */
let currentPosition;

/**
 * ドライバーの現在位置情報をBEに同期する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
export async function syncCurrentLocation(userContext) {
  // 現在位置の取得
  geolocator.requestCurrentPosition(false);
  await sleep(1000);
  currentPosition = get(currentPositionStore);

  // 現在時刻の取得
  now = formatDate(getCurrentDateTimeOnJst(), "yyyy-MM-dd HH:mm:ss");

  let needSync = true;

  if (userContext.deliveryList?.length > 0 && currentPosition) {
    // 持出し中荷物がある場合、配送記録を更新
    needSync = updateDeliveryRecords(userContext, DeliveryRecordTypes.DRIVING);

    if (needSync) {
      // 必要な場合のみ宅配ドライバーとしての現在地をBEに同期
      /** @type {import("~/libs/backendApi").OperationState} */
      const requestBody = {
        driverType: DriverType.DRIVER,
        location: {
          latitude: currentPosition.coords.latitude,
          longitude: currentPosition.coords.longitude,
        },
      };
      await execSyncOperationStateAPI(requestBody, userContext);
    }
  }
  if (
    userContext.inTransitDeliveryList?.length > 0 &&
    currentPosition &&
    needSync
  ) {
    // 輸送中荷物がある場合、幹線ドライバーとしての現在地をBEに同期
    // (宅配ドライバー側で同期不要と判断された場合は同期しない)
    /** @type {import("~/libs/backendApi").OperationState} */
    const requestBody = {
      driverType: DriverType.CORE_DELIVERY,
      location: {
        latitude: currentPosition.coords.latitude,
        longitude: currentPosition.coords.longitude,
      },
    };
    await execSyncOperationStateAPI(requestBody, userContext);
  }
}

/**
 * recordTypeを指定した場合はローカルに保持する配送実績の更新を行い、必要に応じてBEに同期する。
 * recordTypeを指定しない場合は、現在の配送実績をBEに同期する。
 * @param {import("~/libs/commonTypes").UserContext} userContext
 * @param {DeliveryRecordTypes} [recordType] 宅配ドライバーによる同期の場合のみ設定する
 * @param {{
 *   trackingNumber: string,
 *   address: string,
 *   location: {latitude: number, longitude: number},
 *   extraEvent?: number
 * }} [packageInfo] recordTypeが2(配達完了)or3(配達不可)の場合は必須
 */
export async function updateDeliveryRecordsAndSyncBackend(
  userContext,
  recordType,
  packageInfo,
) {
  // 現在位置の取得
  geolocator.requestCurrentPosition(false);
  await sleep(1000);
  currentPosition = get(currentPositionStore);

  // 現在時刻の取得
  now = formatDate(getCurrentDateTimeOnJst(), "yyyy-MM-dd HH:mm:ss");

  if (Number.isInteger(recordType)) {
    // 宅配ドライバーの場合
    // 1. 配送記録の更新
    const needSync = updateDeliveryRecords(
      userContext,
      recordType,
      packageInfo,
    );

    // 2. 配送記録の同期
    if (needSync) {
      await syncOperationStateOfDriver(userContext);
    }
  } else {
    // 幹線ドライバーの場合
    // 1. 配送記録の同期
    await syncOperationStateOfCoreDelivery(userContext);
  }
}

/**
 * 配送実績の更新を行い、BEとの同期が必要かどうかを判定する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 * @param {DeliveryRecordTypes} recordType 配送記録の更新を行う場合は必須
 * @param {{
 *   trackingNumber: string,
 *   address: string,
 *   location: {latitude: number, longitude: number},
 *   extraEvent?: number
 * }} [packageInfo] recordTypeが2(配達完了)or3(配達不可)の場合は必須
 * @returns {boolean} BEとの同期が必要かどうか
 */
function updateDeliveryRecords(userContext, recordType, packageInfo) {
  let needSync = true;
  let totalDistance = userContext.totalDistance ?? 0;
  const deliveryRecords = userContext.deliveryRecords ?? [];

  const newRecord = {
    dateTime: now,
    recordType: recordType,
    location: packageInfo
      ? packageInfo.location
      : {
          latitude: currentPosition?.coords?.latitude,
          longitude: currentPosition?.coords?.longitude,
        },
  };

  /** 実際の現在地情報（移動無しでの配達不可登録の場合のみ、ドライバー現在地を使用） */
  const actualLocation =
    !packageInfo || recordType === DeliveryRecordTypes.UNDELIVERABLE_NO_MOVE
      ? {
          latitude: currentPosition?.coords?.latitude,
          longitude: currentPosition?.coords?.longitude,
        }
      : packageInfo.location;

  /* 前回記録地点からの移動距離(メートル) */
  let distanceSinceLastRecord = 0;
  if (deliveryRecords.length > 0) {
    distanceSinceLastRecord = Math.round(
      calcDistance(
        deliveryRecords[deliveryRecords.length - 1].location?.latitude,
        deliveryRecords[deliveryRecords.length - 1].location?.longitude,
        actualLocation.latitude,
        actualLocation.longitude,
      ),
    );
  }

  switch (recordType) {
    case DeliveryRecordTypes.OUT_FOR_DELIVERY:
    case DeliveryRecordTypes.ALL_DELIVERED:
    case DeliveryRecordTypes.ALL_TAKEBACK:
      // 持出しor全て配達完了or全て持戻り完了
      deliveryRecords.push(newRecord);
      totalDistance += distanceSinceLastRecord;
      break;
    case DeliveryRecordTypes.DRIVING:
      // 配達移動中
      if (distanceSinceLastRecord >= 50) {
        // 前回記録地点から50m以上移動した場合のみ記録
        deliveryRecords.push(newRecord);
        totalDistance += distanceSinceLastRecord;
      } else if (deliveryRecords.length > 0) {
        // 前回記録地点から50m未満、かつ前回記録がある場合は現在地の同期不要
        needSync = false;
      }
      break;
    case DeliveryRecordTypes.DELIVERED:
      // 配達完了
      deliveryRecords.push(
        Object.assign(newRecord, {
          trackingNumber: packageInfo.trackingNumber,
          address: packageInfo.address,
        }),
      );
      totalDistance += distanceSinceLastRecord;
      break;
    case DeliveryRecordTypes.UNDELIVERABLE:
      // 配達不可
      deliveryRecords.push(
        Object.assign(newRecord, {
          trackingNumber: packageInfo.trackingNumber,
          address: packageInfo.address,
          extraEvent: packageInfo.extraEvent,
        }),
      );
      totalDistance += distanceSinceLastRecord;
      break;
    case DeliveryRecordTypes.UNDELIVERABLE_NO_MOVE:
      // 配達不可(移動無し)
      deliveryRecords.push(
        Object.assign(newRecord, {
          trackingNumber: packageInfo.trackingNumber,
          address: packageInfo.address,
          actualLocation: actualLocation,
          extraEvent: packageInfo.extraEvent,
        }),
      );
      break;
  }

  userContext.deliveryRecords = deliveryRecords;
  userContext.totalDistance = totalDistance;
  userContext.store();

  return needSync;
}

/**
 * 宅配ドライバーとしての稼働実績をBEに同期する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function syncOperationStateOfDriver(userContext) {
  // ローカルストレージの配達リストからそれぞれのステータスの送り状番号のリストを作成
  const userContextDeliveryList = userContext.deliveryList ?? [];
  let undeliveredList = userContextDeliveryList
    .filter((e) => e.statusText === "未")
    ?.map((e) => ({
      trackingNumber: e.trackingNumber,
      location: {
        latitude: e.latlon?.latitude,
        longitude: e.latlon?.longitude,
      },
      address: deliveryListUtils.getReferencedAddress(e),
    }));
  let deliveredList = userContextDeliveryList
    .filter((e) => e.statusText === "済")
    ?.map((e) => ({
      trackingNumber: e.trackingNumber,
      location: {
        latitude: e.latlon?.latitude,
        longitude: e.latlon?.longitude,
      },
      address: deliveryListUtils.getReferencedAddress(e),
    }));
  let undeliverableList = userContextDeliveryList
    .filter((e) => e.statusText === "不")
    ?.map((e) => ({
      trackingNumber: e.trackingNumber,
      location: {
        latitude: e.latlon?.latitude,
        longitude: e.latlon?.longitude,
      },
      address: deliveryListUtils.getReferencedAddress(e),
    }));

  // 配達リストにある荷物の持ち出した配送センターIDを重複なしのリスト化
  let locationSourceIdList =
    userContextDeliveryList
      .filter((e) => Number.isInteger(e.actualRelayLocationId))
      ?.map((e) => e.actualRelayLocationId) ?? [];
  locationSourceIdList = [...new Set(locationSourceIdList)];

  /** @type {import("~/libs/backendApi").DeliveryRecordOfDriver}*/
  let deliveryRecordOfDriver = {
    version: CURRENT_IF_VERSION,
    locationSourceIdList: locationSourceIdList,
    undeliveredList: undeliveredList,
    deliveredList: deliveredList,
    undeliverableList: undeliverableList,
    totalDistance: userContext.totalDistance,
    deliveryRecords: userContext.deliveryRecords,
    updatedAt: now,
  };

  /** @type {import("~/libs/backendApi").OperationState} */
  const requestBody = {
    driverType: DriverType.DRIVER,
    results: deliveryRecordOfDriver,
  };

  if (currentPosition) {
    // 現在位置情報が取得できている場合は、最新の位置情報を送信する
    requestBody.location = {
      latitude: currentPosition.coords.latitude,
      longitude: currentPosition.coords.longitude,
    };
  }

  await execSyncOperationStateAPI(requestBody, userContext);
}

/**
 * 幹線輸送ドライバーとしての稼働実績をBEに同期する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function syncOperationStateOfCoreDelivery(userContext) {
  // 現在の輸送中荷物リストから同期用の輸送中荷物リストを作成
  /** @type {Array<import("~/libs/backendApi").inTransitDelivery>} 輸送中の荷物のリスト*/
  let inTransitDeliveryList = [];
  for (const inTransitDeliveryInfoInUserContext of userContext.inTransitDeliveryList ??
    []) {
    let transportSourceId =
      inTransitDeliveryInfoInUserContext.transportSourceId;
    /** @type {Array<{transportDestivationId: number, trackingNumberList: Array<string>}>} */
    let deliveryInfoList = [];

    for (const deliveryInfoInUserContext of inTransitDeliveryInfoInUserContext.deliveryInfoList) {
      let transportDestivationId =
        deliveryInfoInUserContext.transportDestinationId;
      let trackingNumberList = [];

      for (const basketCar of deliveryInfoInUserContext.basketCarList) {
        for (const trackingAndQuantity of basketCar.v) {
          trackingNumberList.push(trackingAndQuantity.trackingNumber);
        }
      }

      /** @type {{transportDestivationId: number, trackingNumberList: Array<string>}} */
      let deliveryInfo = {
        transportDestivationId: transportDestivationId,
        trackingNumberList: trackingNumberList,
      };

      deliveryInfoList.push(deliveryInfo);
    }

    /** @type {import("~/libs/backendApi").inTransitDelivery} */
    let inTransitDelivery = {
      transportSourceId: transportSourceId,
      deliveryInfoList: deliveryInfoList,
    };

    inTransitDeliveryList.push(inTransitDelivery);
  }

  /** @type {import("~/libs/backendApi").DeliveryRecordOfCoreDelivery}*/
  let deliveryRecordOfCoreDelivery = {
    version: CURRENT_IF_VERSION,
    inTransitDeliveryList: inTransitDeliveryList,
    updatedAt: now,
  };

  /** @type {import("~/libs/backendApi").OperationState} */
  const requestBody = {
    driverType: DriverType.CORE_DELIVERY,
    results: deliveryRecordOfCoreDelivery,
  };

  if (currentPosition) {
    // 現在位置情報が取得できている場合は、最新の位置情報を送信する
    requestBody.location = {
      latitude: currentPosition.coords.latitude,
      longitude: currentPosition.coords.longitude,
    };
  }

  await execSyncOperationStateAPI(requestBody, userContext);
}

/**
 * ドライバー稼働状況(配達実績/現在地)同期APIを実施
 * @param {import("~/libs/backendApi").OperationState} requestBody
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function execSyncOperationStateAPI(requestBody, userContext) {
  try {
    await backendApi.syncOperationState(requestBody);
  } catch (error) {
    // 稼働実績が同期できなくても業務は継続できるため、エラーログだけ出して続行
    logger.error(
      "[syncOperationState] 稼働実績の同期でエラーが発生しました",
      {
        username: userContext.loginUser?.username,
      },
      error,
    );
  }
}
