<script>
  import Button, { Label } from "@smui/button";
  import Chip, { Set, Text } from "@smui/chips";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import { beep, destroyAudioContext } from "~/libs/audio";
  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_APP, CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import { getNumberOfInTransitPackagesByIndex } from "~/pages/QrHome/QrHomePickupAndUnload/utils";

  /** 輸送中荷物の情報 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  export let inTransitDeliveryList;

  /**
   * センターIDをキーとしたセンター情報のMap
   * @type {Map<number, import("~/libs/commonTypes").DepotLocation>}
   */
  export let centersMap;

  /** 引継が完了したときに呼び出されるコールバック @type {() => void} */
  export let onTakeoverCompletedCallback;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Html5Qrcode} */
  let html5QrCode;

  /** 引継受入ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** 引継対象の輸送中荷物 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  let takeoverItems;

  onMount(() => {
    html5QrCode = new Html5Qrcode("acceptTakeoverDialogQrCodeScanArea", {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      verbose: false,
    });
  });

  onDestroy(() => {
    if (html5QrCode && html5QrCode.isScanning) {
      html5QrCode.stop();
    }
  });

  /**
   * 輸送中の荷物の引継受入ダイアログを開く。
   */
  export async function openDialog() {
    dialogOpend = true;

    await html5QrCode.start(
      appContext.useManualCameraSelection === true &&
        appContext.selectedBackCameraId
        ? { deviceId: { exact: appContext.selectedBackCameraId } }
        : { facingMode: "environment" },
      { fps: 10, qrbox: { width: 250, height: 250 } },
      onScanSuccess,
      () => {
        // ignore
      },
    );
  }

  /**
   * QRコードスキャン成功時に呼ばれるイベントハンドラ。
   * @param {string} decodedText
   */
  async function onScanSuccess(decodedText) {
    if (html5QrCode.isScanning) {
      if (
        /^\d+\/1\/[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/.test(
          decodedText,
        )
      ) {
        // QRコードのデータが引継情報のフォーマットと一致する場合
        await html5QrCode.stop();
        beep();
        loadingProgress.wrapAsync(async () => {
          try {
            takeoverItems = await backendApi.getWorkTakeover(decodedText);
          } catch (error) {
            if (
              error instanceof HTTPError &&
              (error.response?.status == 403 || error.response?.status == 404)
            ) {
              toast.error($_("errors.takeoverItemNotFoundOrExpired"));
              dialogOpend = false;
              return;
            }
            throw error;
          }
          console.log("takeoverItems:", takeoverItems);
        })();
      } else {
        console.warn("Unrecognized QR code:", decodedText);
      }
    }
  }

  /**
   * 輸送中の荷物の引継受入ダイアログを閉じる。
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  function onCloseDialog(event) {
    if (event.detail?.action === "ok") {
      // 手元の輸送中の荷物リストとuserContextに反映
      const destItems = inTransitDeliveryList ?? [];
      destItems.push(...takeoverItems);
      inTransitDeliveryList = destItems;
      userContext.inTransitDeliveryList = inTransitDeliveryList;
      userContext.store();

      // 親ページの引継完了コールバックを呼び出し
      onTakeoverCompletedCallback();
    }

    takeoverItems = undefined;

    if (html5QrCode.isScanning) {
      html5QrCode.stop();
    }

    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();
  }
</script>

<div class="acceptTakeoverDialog">
  <Dialog
    bind:open={dialogOpend}
    class="wideWidthMdcDialog"
    scrimClickAction={""}
    escapeKeyAction={""}
    on:SMUIDialog:closed={onCloseDialog}
  >
    <Header>
      <Title>輸送中の荷物の引継</Title>
      <IconButton
        action="close"
        ripple={false}
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>

    <Content>
      <span class="description">
        {#if !takeoverItems || takeoverItems.length === 0}
          引継元アプリに表示されているQRコードをスキャンしてください。
        {:else}
          以下の荷物の輸送を引き継ぎます。
        {/if}
      </span>

      <div id="acceptTakeoverDialogQrCodeScanArea"></div>

      {#if takeoverItems && takeoverItems.length > 0}
        <div class="tranceportSourceUnitArea">
          {#each takeoverItems as transportSourceInfo, i}
            <div class="tranceportSourceUnit">
              <p class="tranceportSourceName">
                <strong
                  >{centersMap?.get(transportSourceInfo.transportSourceId)
                    ?.name ?? "【取得エラー】"}</strong
                > 発
              </p>
              <div class="tranceportDestinationUnitArea">
                {#each transportSourceInfo.deliveryInfoList as transportDestinationInfo, j}
                  <p class="tranceportDestinationName">
                    <strong
                      >{centersMap?.get(
                        transportDestinationInfo.transportDestinationId,
                      )?.name ?? "【取得エラー】"}</strong
                    >
                    行（カゴ車{transportDestinationInfo.basketCarList.length.toLocaleString()}台／荷物{getNumberOfInTransitPackagesByIndex(
                      takeoverItems,
                      i,
                      j,
                    ).toLocaleString()}個）
                  </p>
                  <Set
                    chips={transportDestinationInfo.basketCarList}
                    let:chip
                    nonInteractive
                  >
                    <Chip {chip}>
                      <Text tabindex={0}
                        >{chip.v
                          .reduce(function (sum, v) {
                            return sum + v.quantity;
                          }, 0)
                          .toLocaleString()}個</Text
                      >
                    </Chip>
                  </Set>
                {/each}
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </Content>

    <Actions>
      <Button
        variant="unelevated"
        action="ok"
        disabled={!takeoverItems || takeoverItems.length === 0}
      >
        <Label>引継を完了する</Label>
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .acceptTakeoverDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-bottom: 12px;
    }

    .description {
      line-height: 1.4;
      font-size: 15px;
    }

    :global(.mdc-dialog__actions) {
      margin: 0 12px 10px;
      border-top: 1px solid #ddd;
    }
    :global(.mdc-dialog__actions .mdc-button) {
      margin-top: 8px;
    }

    .tranceportSourceUnitArea {
      display: flex;
      flex-direction: column;
      letter-spacing: 0;
      color: rgba(0, 0, 0, 0.75);

      .tranceportSourceUnit {
        &:nth-of-type(n + 2) {
          margin-top: 10px;
        }

        .tranceportSourceName {
          margin-left: 5px;
          font-size: 14px;
        }

        .tranceportDestinationUnitArea {
          margin: 5px 0 0 5px;
          padding: 3px 0 0 2px;
          border-left: solid 5px #c9c7c7;

          .tranceportDestinationName {
            margin-left: 5px;
            margin-bottom: -3px;
            font-size: 14px;
          }

          :global(.mdc-chip) {
            background-color: #018786;
            color: white;
          }
        }
      }
    }
  }
</style>
