<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import List, { Graphic, Item, Meta, Text } from "@smui/list";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    DRIVER_ROLE,
    DriverType,
    QrHomeTypes,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { switchRole } from "~/libs/switchRole";
  import { toast } from "~/libs/toast";
  import workManage from "~/libs/workManage";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {boolean} 設定ダイアログの開閉フラグ */
  let dialogOpend = false;

  /**
   * 切り替え可能なロールのリスト
   * 現時点では固定で宅配業務と幹線輸送業務のみ
   * @type {Array<DRIVER_ROLE | CORE_DELIVERY_ROLE>}
   */
  let switchableRoles = [DRIVER_ROLE, CORE_DELIVERY_ROLE];

  /** 選択中ロールのindex @type {number} */
  let selectedRoleIndex;

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    selectedRoleIndex = userContext.loginUser.roles[0] === DRIVER_ROLE ? 0 : 1;
    dialogOpend = true;
  }

  /**
   * 業務切替処理を行う。
   * 現業務で手持ちの荷物が無い場合は業務を終了。
   * 切替え先業務が業務開始状態でない場合は業務を開始する。
   */
  const changeRole = loadingProgress.wrapAsync(async () => {
    if (
      userContext.hasDriverRole() &&
      deliveryListUtils.canFinishWork(userContext.deliveryList)
    ) {
      // 未配達・配達不可の荷物がひとつもない場合、業務を終了
      await workManage.finish(userContext, DriverType.DRIVER);
    } else if (
      userContext.hasContractDriverRole() &&
      userContext.getNumberOfInTransitPackages() === 0
    ) {
      // 輸送中荷物がひとつもない場合、業務を終了
      await workManage.finish(userContext, DriverType.CORE_DELIVERY);
    }

    try {
      await switchRole(userContext, switchableRoles[selectedRoleIndex]);

      // UserContextの設定を保存
      userContext.store();

      // 必要に応じて業務開始処理を行い、画面遷移
      if (switchableRoles[selectedRoleIndex] === DRIVER_ROLE) {
        if (!userContext.duringDriverWorking) {
          // 宅配業務開始
          await workManage.begin(userContext, DriverType.DRIVER);
          userContext.store();
        }
        pageRouter.moveToList();
      } else {
        if (!userContext.duringCoreDeliveryWorking) {
          // 幹線輸送業務開始
          await workManage.begin(userContext, DriverType.CORE_DELIVERY);
          userContext.store();
        }
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
      }
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        // サーバーエラー応答等が発生した場合
        logger.error(
          "[RoleChangeDialog] ロール切替えでエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }
  });
</script>

{#if dialogOpend}
  <div class="configurationDialog">
    <Dialog bind:open={dialogOpend}>
      <Header>
        <Title>業務切替</Title>
        <IconButton action="close" class="material-icons" ripple={false}
          >close</IconButton
        >
      </Header>

      <Content>
        <List class="demo-list" avatarList singleSelection>
          {#each switchableRoles as role, i}
            <Item
              on:SMUI:action={() => (selectedRoleIndex = i)}
              selected={selectedRoleIndex === i}
            >
              <Graphic
                style="color: #fff; background-color: #018786; border-radius: 50%;"
                >{$_(`classes.shortRoleName.${role}`)}</Graphic
              >
              <Text>
                {$_(`classes.businessName.${role}`)}
              </Text>
              {#if selectedRoleIndex === i}
                <Meta class="material-icons" style="color: #018786;">done</Meta>
              {/if}
            </Item>
          {/each}
        </List>
        <div class="buttonArea">
          <Button
            touch
            disabled={switchableRoles[selectedRoleIndex] ===
              userContext.loginUser.roles[0]}
            color="secondary"
            on:click={changeRole}
          >
            <Label>切替</Label>
          </Button>
        </div>
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .configurationDialog {
    :global(.mdc-dialog) {
      z-index: 201;
    }

    :global(.mdc-dialog__header .mdc-icon-button) {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    :global(.mdc-deprecated-list-item--selected) {
      color: #018786;
    }

    :global(
        :not(
            .mdc-deprecated-list-item--disabled
          ).mdc-deprecated-list-item--selected
          .mdc-deprecated-list-item__ripple::before
      ) {
      background-color: #018786;
    }

    .buttonArea {
      text-align: right;
    }
  }
</style>
