<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import imageCompression from "browser-image-compression";
  import { createEventDispatcher, getContext } from "svelte";

  import additionalDataForDeliverylist from "~/libs/additionalDataForDeliverylist";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  export let trackingNumber;

  /** @type {boolean} ESCキーやblurでダイアログを閉じないようにするか否か */
  let mandatory = true;

  /**
   * @callback onDialogClosedCallback
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  /**
   * @type {onDialogClosedCallback}
   */
  let onDialogClosedHandler = () => {};

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpened = false;

  let deliveryList = userContext.deliveryList;

  /** @type {number} */
  let index;
  for (let i = 0; i < deliveryList.length; i++) {
    if (trackingNumber === deliveryList[i].trackingNumber) {
      index = i;
      break;
    }
  }

  /** @type {string} 荷姿や送り状の写真のデータURL */
  let photoDataUrlForPackaging;

  /** @type {string} */
  let memoValue;

  /**
   * ダイアログを開く。
   */
  export async function openDialog() {
    dialogOpened = true;
    memoValue = deliveryList[index].personalMemo
      ? deliveryList[index].personalMemo
      : "";
    photoDataUrlForPackaging =
      await additionalDataForDeliverylist.getPersonalMemoPhoto(
        deliveryList[index].trackingNumber,
      );
  }

  function closeDialog() {
    dialogOpened = false;
  }

  async function registMemo() {
    deliveryList[index].personalMemo = memoValue ?? "";
    userContext.deliveryList = deliveryList;
    userContext.store();

    if (photoDataUrlForPackaging) {
      await additionalDataForDeliverylist.registPersonalMemoPhoto(
        deliveryList[index].trackingNumber,
        photoDataUrlForPackaging,
      );
    } else {
      await additionalDataForDeliverylist.deleteByTrackingNumber(
        deliveryList[index].trackingNumber,
      );
    }

    dispatch("update", {
      personalMemo: memoValue,
      photoDataUrlForPackaging: photoDataUrlForPackaging,
    });
    closeDialog();
    toast.info("個人メモを登録しました");
  }

  /**
   * 写真ファイルが選択された時の処理
   * @param {Event} event
   */
  async function photoChange(event) {
    /** @type {FileList} */
    const files = event.target.files;

    const photoFileForPackaging = files[0];

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    photoDataUrlForPackaging = await imageCompression.getDataUrlFromFile(
      await imageCompression(photoFileForPackaging, options),
    );
  }
</script>

{#if dialogOpened}
  <div class="personalMemoDialog">
    <Dialog
      bind:open={dialogOpened}
      scrimClickAction={mandatory ? "" : "close"}
      escapeKeyAction={mandatory ? "" : "close"}
      on:SMUIDialog:closed={onDialogClosedHandler}
      aria-labelledby="preset-dialog-title"
      aria-describedby="preset-dialog-content"
    >
      <Header>
        <Title id="preset-dialog-title">個人メモの登録</Title>
        <IconButton
          action="close"
          ripple={false}
          class="material-icons"
          style="position: absolute; top: 5px; right: 5px;">close</IconButton
        >
      </Header>
      <Content id="preset-dialog-content">
        <p class="helperText">
          荷物を持ち出してから、配達完了または持ち戻るまで有効な自分専用のメモ欄です。
        </p>

        <!-- メモ入力欄 -->
        <Textfield
          style="width: 100%; height: 10rem;"
          textarea
          bind:value={memoValue}
        ></Textfield>

        <!-- 写真ファイルのアップロード -->
        <div class="uploadArea">
          <label class="uploadBtn">
            <span
              class="material-icons"
              style="font-size: 18px; vertical-align: middle; color: #018786;"
            >
              image
            </span>
            荷姿や送り状の写真を残す
            <input
              type="file"
              capture="environment"
              accept="image/*"
              id="photoInput"
              on:change={photoChange}
            />
          </label>
        </div>

        <!-- プレビュー画面（ファイルがアップロードされた時のみ表示） -->
        {#if photoDataUrlForPackaging}
          <div class="previewArea">
            <img
              src={photoDataUrlForPackaging}
              class="previewImage"
              alt="preview"
              width="100%"
            />
            <button
              class="deletePhotoButton"
              on:click={() => {
                photoDataUrlForPackaging = null;
              }}><span class="material-icons"> cancel </span></button
            >
          </div>
        {/if}

        <div class="buttonArea">
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={() => {
              registMemo();
            }}
          >
            <Label>登録する</Label>
          </Button>
        </div>
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .personalMemoDialog {
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: calc(100vw - 32px);
    }
    :global(.mdc-dialog__header) {
      text-align: center;
    }
    .buttonArea {
      margin-top: 15px;
      :global(.registerButton) {
        width: 100%;
      }
    }
  }

  .helperText {
    font-size: 13.6px;
    color: rgba(0, 0, 0, 0.6);
    line-height: normal;
    margin-bottom: 10px;
  }

  /* アップロードボタン */
  .uploadArea {
    position: relative;
    text-align: right;
    .uploadBtn {
      padding: 0;
      margin: 0;
      font-size: 13px;
      color: #018786;
      background-color: #fff;
      border: none;
      text-decoration: underline;
    }
    input[type="file"] {
      display: none;
    }
  }

  /* プレビュー画面 */
  .previewArea {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 4px;
  }

  /* 削除ボタン */
  .deletePhotoButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 50%;
    background-color: white;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    .material-icons {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
</style>
