<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import QRCode from "qrcode";
  import { getContext } from "svelte";

  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_USER, WorkTakeoverTypes } from "~/libs/constants";
  import logger from "~/libs/logger";

  /** 輸送中荷物の情報 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  export let inTransitDeliveryList;

  /** 引継が完了したときに呼び出されるコールバック @type {() => void} */
  export let onTakeoverCompletedCallback;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 引継要求ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** 引継対象の輸送中荷物 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  let takeoverItems;

  /** 引継用QRコードのデータURL @type {string} */
  let takeoverQrCodeDataUrl;

  /** 引継完了フラグ */
  let takeoverCompleted = false;

  /**
   * 輸送中の荷物の引継要求ダイアログを開く。
   * @param {number} transportDestinationId 幹線輸送先の配送センターID
   */
  export async function openDialog(transportDestinationId) {
    takeoverItems = inTransitDeliveryList
      .map((e1) => ({
        transportSourceId: e1.transportSourceId,
        deliveryInfoList: e1.deliveryInfoList
          .filter((e2) => e2.transportDestinationId === transportDestinationId)
          .map((e2) => ({
            transportDestinationId: e2.transportDestinationId,
            basketCarList: e2.basketCarList
              .filter((e3) => e3.c)
              .map((e3) => ({ k: e3.k, v: e3.v })),
          }))
          .filter((e2) => e2.basketCarList.length > 0),
      }))
      .filter((e1) => e1.deliveryInfoList.length > 0);

    const takeoverUri = await backendApi.postWorkTakeover(
      WorkTakeoverTypes.IN_TRANSIT_PACKAGES,
      takeoverItems,
    );
    takeoverQrCodeDataUrl = await QRCode.toDataURL(takeoverUri, {
      margin: 0,
      scale: 9,
    });
    dialogOpend = true;
  }

  /**
   * 輸送中の荷物の引継要求ダイアログを閉じる。
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  function onCloseDialog(event) {
    // 引継が完了したかに関わらず引継データは削除（空データの登録）
    if (takeoverQrCodeDataUrl) {
      backendApi
        .postWorkTakeover(WorkTakeoverTypes.IN_TRANSIT_PACKAGES, [])
        .catch((error) => {
          logger.error(
            "[RequestTakeoverDialog] 引継済データの削除でエラーが発生しました",
            {
              username: userContext.loginUser?.username,
              takeoverQrCodeDataUrl,
              takeoverItems,
            },
            error,
          );
        });
    }

    if (event.detail?.action === "ok") {
      // 親ページの引継完了コールバックを呼び出し
      onTakeoverCompletedCallback();
    }

    takeoverItems = undefined;
    takeoverQrCodeDataUrl = undefined;
    takeoverCompleted = false;
  }
</script>

<div class="requestTakeoverDialog">
  <Dialog
    bind:open={dialogOpend}
    class="wideWidthMdcDialog"
    scrimClickAction={""}
    escapeKeyAction={""}
    on:SMUIDialog:closed={onCloseDialog}
  >
    <Header>
      <Title>輸送中の荷物の引継</Title>
      <IconButton
        action="close"
        ripple={false}
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>

    <Content>
      <span class="description">
        引継先アプリの荷受け画面で以下のQRコードをスキャンしてもらい、引継が完了したことを確認のうえ続行してください。
      </span>

      <div class="qrcode">
        <img src={takeoverQrCodeDataUrl} alt="QRコード" />
      </div>

      <FormField>
        <Checkbox bind:checked={takeoverCompleted} />
        <span slot="label">引継が完了していることを確認した</span>
      </FormField>
    </Content>

    <Actions>
      <Button variant="unelevated" action="ok" disabled={!takeoverCompleted}>
        <Label>引継が完了した荷物を削除する</Label>
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .requestTakeoverDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-bottom: 6px;
    }

    .description {
      line-height: 1.4;
      font-size: 15px;
    }

    .qrcode {
      text-align: center;
      margin-top: 8px;
    }

    :global(.mdc-dialog__actions) {
      margin: 0 12px 10px;
      border-top: 1px solid #ddd;
    }
    :global(.mdc-dialog__actions .mdc-button) {
      margin-top: 8px;
    }
  }
</style>
