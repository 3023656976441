/* Context API用のキー */
export const CONTEXT_KEY_APP = "Symbol(1)";
export const CONTEXT_KEY_USER = "Symbol(2)";

/** トースト表示時間 */
export const TOAST_DURATION = 6000;

export const DRIVER_ROLE = "shipping-partner-driver";
export const CORE_DELIVERY_ROLE = "contract-driver";
export const DISABLE_ROLES = [
  "contract-admin",
  "shipping-partner-admin",
  "ec-admin",
  "scc-operator",
];

export const TO_IN_TRANSIT = "荷受け・輸送";
export const TO_OUT_FOR_DELIVERY = "持ち出し";
export const TO_HELD_IN_DEPOT = "持ち戻り・返品";
export const TO_RETURN_TO_EC = "返品集荷・引渡";

// 配送ステータス
/** 配送ステータス：出荷待ち */
export const STATUS_CREATED = 0;
/** 配送ステータス：輸送中 */
export const STATUS_IN_TRANSIT = 1;
/** 配送ステータス：保管中 */
export const STATUS_HELD_IN_DEPOT = 2;
/** 配送ステータス：持出中 */
export const STATUS_OUT_FOR_DELIVERY = 3;
/** 配送ステータス：配達完了 */
export const STATUS_DELIVERED = 4;

// 返品用ステータス
/** 配送ステータス：返品要求中 */
export const STATUS_REQUESTING_FOR_RETURN = 0;
/** 配送ステータス：返品待ち */
export const STATUS_WAITING_FOR_RETURN = 1;
/** 配送ステータス：返品中 */
export const STATUS_RETURNING = 2;
/** 配送ステータス：返品済 */
export const STATUS_RETURNED = 3;

/** 再配達の時間帯作成で用いる選択肢 */
export const CHOICES_OF_TIME = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

/** 再配達の受け取り可能時間帯なしの場合の入力値 */
export const NO_PICKUP_TIMEFRAME = "0000";

/**
 * 返品理由の区分
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6}
 */
export const ReturnReason = Object.freeze({
  /** EC事業者から返品要求 */
  REQUESTED_RETURN_FROM_EC: 0,
  /** 荷受人から注文キャンセル要求 */
  REQUESTED_CANCEL_FROM_RECEIVER: 1,
  /** 受け取り拒否 */
  ACCEPT_DENIED: 2,
  /** 宛先住所間違い */
  ADDRESS_WRONG: 3,
  /** 宛先住所不明 */
  ADDRESS_UNKNOWN: 4,
  /** 再配達回数上限超過 */
  REDELIVERY_LIMIT_EXCEEDED: 5,
  /** 荷物に問題が発生した */
  SHIPMENT_PROBLEM: 6,
});

/**
 * 受け渡し方法
 * @enum {0 | 1 | 2 | 3 | 4 | 5}
 */
export const DropOffLocation = Object.freeze({
  /** 玄関ドア前(0) */
  BY_THE_DOOR: 0,
  /** 宅配ボックス(1) */
  LOCKER: 1,
  /** メーターボックス(2) */
  METERBOX: 2,
  /** 郵便受け(3) */
  MAILBOX: 3,
  /*** エントランス(4) @deprecated */
  ENTRANCE: 4,
  /*** 手渡し(5) */
  HANDOVER: 5,
});

/**
 * QrHome画面の表示タイプ
 * @typedef {1 | 2 | 3 | 4} QrHomeType
 * @enum {QrHomeType}
 */
export const QrHomeTypes = Object.freeze({
  PICKUP_AND_SORT: 1,
  OUT_FOR_DELIVERY: 2,
  TAKEBACK_PACKAGE_TO_DEPOT: 3,
  RETURN_TO_EC: 4,
});

/**
 * 配送拠点の種別 (ビットフラグ)
 * @typedef {1 | 2 | 4} DepotType
 * @enum {DepotType}
 */
export const DepotTypes = Object.freeze({
  EC: 1 << 0,
  CONTRACT: 1 << 1,
  TRUMP: 1 << 2,
});

/**
 * QrHomeTypeごとに一覧に表示する配送拠点の種別
 * @type {Map<QrHomeType, number>}
 */
export const ShowDepotTypes = Object.freeze(
  new Map([
    [QrHomeTypes.PICKUP_AND_SORT, DepotTypes.EC],
    [QrHomeTypes.OUT_FOR_DELIVERY, DepotTypes.CONTRACT | DepotTypes.TRUMP],
    [
      QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT,
      DepotTypes.CONTRACT | DepotTypes.TRUMP,
    ],
    [QrHomeTypes.RETURN_TO_EC, DepotTypes.CONTRACT | DepotTypes.TRUMP],
  ]),
);

/**
 * オフラインモードの表示タイプ
 * @typedef {1 | 2} OfflineModeType
 * @enum {OfflineModeType}
 */
export const OfflineModeTypes = Object.freeze({
  PICKUP_AND_SORT: 1,
  DELIVERED: 2,
});

/**
 * パスワード強度
 * @enum {0 | 1 | 2}
 */
export const PasswordStrength = Object.freeze({
  WEAK: 0,
  MEDIUM: 1,
  STRONG: 2,
});

/**
 * @enum {"LOGIN" | "UPDATE_SHIPMENT_STATUS" | "UPDATE_RETURN_STATUS"}
 */
export const BackendApiName = Object.freeze({
  LOGIN: "LOGIN",
  UPDATE_SHIPMENT_STATUS: "UPDATE_SHIPMENT_STATUS",
  UPDATE_RETURN_STATUS: "UPDATE_RETURN_STATUS",
});

/**
 * 確認ダイアログのタイプ
 * @typedef {0 | 1 | 2 | 3 | 4} ConfirmDialogType
 * @enum {ConfirmDialogType}
 */
export const ConfirmDialogTypes = Object.freeze({
  NONE: 0,
  OK_CANCEL_CLOSE: 1,
  OK_CLOSE: 2,
  OK: 3,
  CLOSE: 4,
});

/**
 * 利用可能な受け渡し方法の一覧
 * @type {Array<DropOffLocation | number>}
 */
// @ts-ignore
export const AVAILABLE_DROP_OFF_LOCATIONS = Object.freeze([
  DropOffLocation.HANDOVER,
  DropOffLocation.BY_THE_DOOR,
  DropOffLocation.LOCKER,
  DropOffLocation.METERBOX,
  DropOffLocation.MAILBOX,
]);

/**
 * 地図表示に使用する住所の区分
 * @enum {1 | 2 | 3}
 */
export const AddressTypeForMap = Object.freeze({
  /** 登録住所 */
  REGISTERED: 1,
  /** 宅配ドライバーが手入力した住所 */
  INPUTTED: 2,
  /** 訂正住所 */
  CORRECTED: 3,
});

// トラブル
/** トラブル：住所誤り(EC事業者責) */
export const TROUBLE_NON_ADDRESS = 0;
/** トラブル：住所位置不明(宅配事業者責) */
export const TROUBLE_ADDRESS_UNKNOWN = 1;
/** トラブル：紛失／損壊 */
export const TROUBLE_LOST_OR_DAMAGED = 2;
/** トラブル：持ち戻り */
export const UNABLE_TO_DELIVER_TODAY = 3;
/** トラブル：不在・置き配不可 */
export const ABSENCE = 4;
/** トラブル：受取拒否 */
export const ACCEPT_DENIED = 5;
/** トラブル：代引き支払不可 */
export const PAYMENT_NOT_POSSIBLE = 6;

// リマインド
/** リマインド：デフォルト通知時間(時) */
export const REMIND_HOURS = 20;
/** リマインド：デフォルト通知時間(分) */
export const REMIND_MINUTES = 30;

/** Googlマップのiframe表示に使用するベースURL */
export const GOOGLE_MAP_BASE_URL =
  "https://maps.google.com/maps?output=embed&q=<address>&t=m&hl=ja&z=18";

/**
 * 通知カテゴリー
 * @enum {0 | 1 | 2}
 */
export const NotificationCategory = Object.freeze({
  INFO: 0,
  ERROR: 1,
  PUSH: 2,
});

/**
 * AppPageの種別（ログイン前、ログイン後、オフラインモード）
 * @typedef {1 | 2 | 3} AppPageType
 * @enum {AppPageType}
 */
export const AppPageTypes = Object.freeze({
  BEFORE_LOGIN: 1,
  AFTER_LOGIN: 2,
  OFFLINE_MODE: 3,
});

/**
 * スキャン時配送情報取得APIの実行タイプ
 * @typedef {"pickupAndSort" | "outForDelivery" | "takeBackPackageToDepot" | "returnToEc"} QrScanMode
 * @enum {QrScanMode}
 */
export const QrScanModes = Object.freeze({
  PICKUP_AND_SORT: "pickupAndSort",
  OUT_FOR_DELIVERY: "outForDelivery",
  TAKEBACK_PACKAGE_TO_DEPOT: "takeBackPackageToDepot",
  RETURN_TO_EC: "returnToEc",
});

/**
 * 地図表示に使用する住所の区分
 * @enum {0 | 1}
 */
export const LostOrDamagedTypes = Object.freeze({
  /** 紛失 */
  LOST: 0,
  /** 損壊 */
  DAMAGED: 1,
});

/**
 * 再配達日時の調整方法
 */
export const RedeliveryDateAdjustMethod = Object.freeze({
  WEB: 0,
  TEL: 1,
});

/**
 * 荷受人による再配達日時の指定方法
 */
export const RedeliveryDateSpecifyMethod = Object.freeze({
  DESIRED_DATE_AND_TIME: 0,
  AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK: 1,
});

/**
 * 代引き金額支払不可の検知タイプ
 * @enum {0 | 1}
 */
export const PaymentNotPossibleDetectionTypes = Object.freeze({
  /** 配達先へ移動する前 */
  BEFORE_ARRIVAL: 0,
  /** 配達先へ移動した後 */
  AFTER_ARRIVAL: 1,
});

/**
 * 受取拒否の理由区分
 * @enum {0 | 1 | 2}
 */
export const acceptDeniedReasonTypes = Object.freeze({
  /** 宛名誤り */
  WRONG_DESTINATION_NAME: 0,
  /** 不要になった */
  NO_LONGER_NEEDED: 1,
  /** その他 */
  OTHERS: 2,
});

/**
 * プッシュ通知の受信タイプ
 * @typedef {0 | 1 | 2} ReceivedPushType
 * @enum {ReceivedPushType}
 */
export const receivedPushTypes = Object.freeze({
  CORRECTED_RECEIVER_ADDRESS: 0,
  INTERNAL_MESSAGE: 1,
  ADJUSTED_REDELIVERY_DATETIME: 2,
});

/**
 * serviceWorkerからアプリへの実行メソッド区分
 * @typedef {0 | 1} MethodTypeOnMessage
 * @enum {MethodTypeOnMessage}
 */
export const methodTypeOnMessage = Object.freeze({
  RECIEVED_PUSH: 0,
  OPENED_PUSH: 1,
});

/**
 * 荷受時の作業内容
 * @enum {0 | 1 }
 */
export const inTransitWorkTypes = Object.freeze({
  /** 一括荷受 */
  BULK_RECEIVE: 0,
  /** 個別スキャン */
  INDIVIDUAL_SCAN: 1,
});

/**
 * 作業引継のタイプ
 * @typedef {1} WorkTakeoverType
 * @enum {WorkTakeoverType}
 */
export const WorkTakeoverTypes = Object.freeze({
  IN_TRANSIT_PACKAGES: 1,
});

/**
 * ローカルに保持する配送記録のレコードタイプ
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6}
 */
export const DeliveryRecordTypes = Object.freeze({
  /** 持出し */
  OUT_FOR_DELIVERY: 0,
  /** 配達移動中 */
  DRIVING: 1,
  /** 配達完了 */
  DELIVERED: 2,
  /** 配達不可 */
  UNDELIVERABLE: 3,
  /** 全て配達完了 */
  ALL_DELIVERED: 4,
  /** 全て持ち戻り完了 */
  ALL_TAKEBACK: 5,
  /** 配達不可(移動無し) */
  UNDELIVERABLE_NO_MOVE: 6,
});

/**
 * 業務従事状況を同期する際のドライバー区分
 * @enum {0 | 1}
 */
export const DriverType = Object.freeze({
  /** 幹線輸送 */
  CORE_DELIVERY: 0,
  /** 宅配 */
  DRIVER: 1,
});

/**
 * 業務終了直前のドライバー操作区分
 * @enum {0 | 1}
 */
export const LastOperationTypes = Object.freeze({
  /** 配達完了 */
  ALL_DELIVERED: 0,
  /** 持戻り完了 */
  ALL_TAKEBACK: 1,
});
