<script>
  import { createEventDispatcher, getContext } from "svelte";

  import HelpAfterLogin from "~/components/help/HelpAfterLogin.svelte";
  import HelpAfterTrouble from "~/components/help/HelpAfterTrouble.svelte";
  import HelpBeforeLogin from "~/components/help/HelpBeforeLogin.svelte";
  import HelpBulkReceive from "~/components/help/HelpBulkReceive.svelte";
  import HelpDeliveryComplete from "~/components/help/HelpDeliveryComplete.svelte";
  import HelpDeliveryList from "~/components/help/HelpDeliveryList.svelte";
  import HelpIndividualScan from "~/components/help/HelpIndividualScan.svelte";
  import HelpOutForDelivery from "~/components/help/HelpOutForDelivery.svelte";
  import HelpReturn from "~/components/help/HelpReturn.svelte";
  import HelpSelectMethod from "~/components/help/HelpSelectMethod.svelte";
  import HelpTakeOver from "~/components/help/HelpTakeOver.svelte";
  import HelpUnloading from "~/components/help/HelpUnloading.svelte";
  import BulkReceive from "~/components/icons/bulkReceive.svelte";
  import Delivery from "~/components/icons/delivery.svelte";
  import DeliveryComplete from "~/components/icons/deliveryComplete.svelte";
  import Door from "~/components/icons/door.svelte";
  import Notification from "~/components/icons/notification.svelte";
  import OutForDelivery from "~/components/icons/outForDelivery.svelte";
  import ReturnPackage from "~/components/icons/returnPackage.svelte";
  import Select from "~/components/icons/select.svelte";
  import Unloading from "~/components/icons/unloading.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let dispatch = createEventDispatcher();

  function openHelpBeforeLogin() {
    dispatch("confirm", HelpBeforeLogin);
  }

  function openHelpAfterLogin() {
    dispatch("confirm", HelpAfterLogin);
  }

  function openHelpOutForDelivery() {
    dispatch("confirm", HelpOutForDelivery);
  }

  function openHelpDeliveryList() {
    dispatch("confirm", HelpDeliveryList);
  }

  function openHelpDeliveryComplete() {
    dispatch("confirm", HelpDeliveryComplete);
  }

  function openHelpAfterTrouble() {
    dispatch("confirm", HelpAfterTrouble);
  }

  function openHelpSelectMethod() {
    dispatch("confirm", HelpSelectMethod);
  }

  function openHelpBulkReceive() {
    dispatch("confirm", HelpBulkReceive);
  }

  function openHelpIndividualScan() {
    dispatch("confirm", HelpIndividualScan);
  }

  function openHelpUnloading() {
    dispatch("confirm", HelpUnloading);
  }

  function openHelpTakeOver() {
    dispatch("confirm", HelpTakeOver);
  }

  function openHelpReturn() {
    dispatch("confirm", HelpReturn);
  }

  function confirm() {
    dispatch("confirm");
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>アプリの使い方</p>
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea">
  {#if userContext.hasDriverRole()}
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpOutForDelivery"
          on:click={openHelpBeforeLogin}
        >
          <div class="helpButtonIcon">
            <Door />
          </div>
          <p class="helpButtonText">①ログイン方法と<br />パスワード変更</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryList"
          on:click={openHelpAfterLogin}
        >
          <div class="helpButtonIcon">
            <Notification />
          </div>
          <p class="helpButtonText">
            ②位置情報の利用と<br />通知の許可について
          </p>
        </button>
      </div>
    </div>
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpOutForDelivery"
          on:click={openHelpOutForDelivery}
        >
          <div class="helpButtonIcon">
            <OutForDelivery />
          </div>
          <p class="helpButtonText">③持ち出し登録の流れ</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryList"
          on:click={openHelpDeliveryList}
        >
          <div class="helpButtonIcon">
            <Delivery />
          </div>
          <p class="helpButtonText">④配達先への移動</p>
        </button>
      </div>
    </div>
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryComplete"
          on:click={openHelpDeliveryComplete}
        >
          <div class="helpButtonIcon">
            <DeliveryComplete />
          </div>
          <p class="helpButtonText">⑤配達完了／配達不可<br />登録の流れ</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpAfterTrouble"
          on:click={openHelpAfterTrouble}
        >
          <div class="helpButtonIcon">
            <ReturnPackage />
          </div>
          <p class="helpButtonText">⑥持ち戻り／返品の流れ</p>
        </button>
      </div>
    </div>
  {:else}
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpOutForDelivery"
          on:click={openHelpSelectMethod}
        >
          <div class="helpButtonIcon">
            <Select />
          </div>
          <p class="helpButtonText">①荷受け方法の選択</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryList"
          on:click={openHelpBulkReceive}
        >
          <div class="helpButtonIcon">
            <BulkReceive />
          </div>
          <p class="helpButtonText">②一括荷受け</p>
        </button>
      </div>
    </div>
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpOutForDelivery"
          on:click={openHelpIndividualScan}
        >
          <div class="helpButtonIcon">
            <OutForDelivery />
          </div>
          <p class="helpButtonText">③個別スキャン</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryList"
          on:click={openHelpUnloading}
        >
          <div class="helpButtonIcon">
            <Unloading />
          </div>
          <p class="helpButtonText">④荷下ろし・引継ぎ</p>
        </button>
      </div>
    </div>
    <div class="helpContentsLine">
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpDeliveryComplete"
          on:click={openHelpTakeOver}
        >
          <div class="helpButtonIcon">
            <DeliveryComplete />
          </div>
          <p class="helpButtonText">⑤引継ぎを受ける</p>
        </button>
      </div>
      <div class="helpContent">
        <button
          class="helpButton"
          id="helpAfterTrouble"
          on:click={openHelpReturn}
        >
          <div class="helpButtonIcon">
            <ReturnPackage />
          </div>
          <p class="helpButtonText">⑥返品集荷・引渡し</p>
        </button>
      </div>
    </div>
  {/if}
</div>
<!-- ページ管理エリア -->
<div class="confirmButtonArea">
  <button class="confirmButton" on:click={confirm}>閉じる</button>
</div>

<style lang="scss">
  /* タイトルエリア */
  .titleArea {
    margin: 32px 0 20px;
    text-align: center;
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: column;
    width: 100%;
    height: 390px;
  }
  .helpContentsLine {
    display: flex;
    margin: 0 auto;
    width: 90%;
    height: 33%;
  }
  .helpContent {
    width: 50%;
    text-align: center;
  }
  .helpButton {
    height: 95%;
    width: 95%;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    appearance: none;
    background-color: #f0f8f8;
    border: none;
    border-radius: 20px;
  }
  .helpButtonIcon {
    height: 70%;
    margin-bottom: -20px;
    :global(.svg-icons-door) {
      margin-top: 8px;
      width: 50px;
      height: 50px;
    }
    :global(.svg-icons-notification) {
      margin-top: 5px;
      width: 50px;
      height: 50px;
    }
    :global(.svg-icons-out-for-delivery) {
      margin-top: 5px;
      width: 50px;
      height: 50px;
    }
    :global(.svg-icons-delivery) {
      margin-top: -3px;
      width: 75px;
      height: 75px;
    }
    :global(.svg-icons-delivery-complete) {
      margin-top: 7px;
      width: 50px;
      height: 50px;
    }
    :global(.svg-icons-return-package) {
      margin-top: 5px;
      width: 50px;
      height: 50px;
    }
  }
  .helpButtonText {
    font-size: 13px;
    font-weight: bold;
    color: #203a72;
  }
  /* ボタンエリア */
  .confirmButtonArea {
    position: relative;
    width: 100%;
    height: 90px;
    text-align: center;
  }
  .confirmButton {
    position: absolute;
    transform: translateX(-50%);
    display: inline-block;
    margin-top: 20px;
    width: 130px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
</style>
